<template>
    <div>
        <div class="main">
            <div class="title">
                马上服务
            </div>

            <div class="box">
                <div class="avatar">
                    <div class="smallTitle">
                        头像
                    </div>
                    <!-- <img src="../assets/upload_avatar.png" alt="" srcset=""> -->
                    <el-upload
                        class="avatar-uploader"
                        action="https://www.kmdnkj.com/api/index/index/method/images.upload"
                        style="border:1px #666 dashed;height:100px;width:100px;border-radius:50%;line-height:100px"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload">
                        <img v-if="imageUrl" :src="imageUrl" class="avatar_image">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                </div>
                <div class="name">
                    <div class="smallTitle">
                        姓名
                    </div>
                    <input type="text" v-model="user_name" placeholder="请输入您的姓名">
                </div>
                <div class="name">
                    <div class="smallTitle">
                        服务类型
                    </div>
                    <el-select v-model="pression" placeholder="请选择" @change="change">
                        <el-option
                        v-for="item in options"
                        :key="item.key"
                        :label="item.value"
                        :value="item.key">
                        </el-option>
                    </el-select>
                </div>
                <div class="desc">
                    <div class="smallTitle" style="margin-top:30px">
                        个人简介
                    </div>
                    <textarea name="" id="" cols="30" rows="10" class="text_class" v-model="Personalprofile" placeholder="请输入不少于100字的简介"></textarea>
                 </div>
                 <div class="desc">
                    <div class="smallTitle" style="margin-top:30px">
                        个人说明
                    </div>
                    <textarea name="" id="" cols="30" rows="10" class="text_class" v-model="Personaldescription" placeholder="请输入不少于100字的简介"></textarea>
                 </div>
                 <div class="service_title" style="display: flex; align-items: center;">
                    <b>同意协议</b>
                    <img src="../assets/aqua.png" mode="" style="height: 20px; width: 22px; margin-left: 20px;" @click="agree = !agree" v-if="!agree">
                    <img src="../assets/agree.png" mode="" style="height: 20px; width: 22px; margin-left: 20px;" @click="agree = !agree" v-else>
                    <span style="font-size: 25upx; color: #999;" @click="get_Art">《标书人工审核功能协议》</span>
                </div>
                <div class="baocun" @click="submit">提交</div>
            </div>
        </div>

         <div class="mask" v-show="show_result" @click.self="show_result = !show_result">
             <div class="result_box">
                 <!-- <img :src="result == '1'?'../assets/success.png':(result == '2'?'../assets/now.png':'../assets/fail.png')" alt="" srcset=""> -->
                 <img src="../assets/success.png" alt="" srcset="" v-if="result == 'success'">
                 <img src="../assets/now.png" alt="" srcset="" v-if="result == 'now'" style="height:377px;width:548px;">
                 <img src="../assets/fail.png" alt="" srcset="" v-if="result == 'fail'">
                 <img src="../assets/close.png" alt="" srcset="" class="close_icon" @click.self="show_result = !show_result">
                 <div class="result_text" v-if="result == 'success'">
                     恭喜您，您的账号审核成功！
                 </div>
                 <div class="result_text" v-if="result == 'now'">
                     您的信息正在审核中... ...
                 </div>
                 <div class="result_text" v-if="result == 'fail'">
                     对不起，您的信息未通过审核
                     <div class="resign_button center" style="margin-top:10px"  @click="get_resign">点此重新提交</div>
                 </div>
             </div>

         </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            options:[{value:'专家',key:1},{value:'律师',key:2}],
            pression:'',
            user_name:'',
            agree:false,
            imageUrl:'',
            image_id:'',
            Personalprofile:'',
            Personaldescription:'',
            type:'',//1:专家2:律师
            show_result:false,
            result:'',
            resign_obj:''
        }
    },
    watch:{
        show_result(val){
            if(val){
                document.body.style.overflow = 'hidden'
            }else{
                document.body.style.overflow = ''
            }
        }
    },
    created(){
        if(this.$local.get('now_shenhe')){
            this.show_result = true
            this.load_result()
        }else{

        }
    },
    methods:{
        get_Art(){
			this.$router.push({path:'/artical',query:{id:3}})
		},
        submit(){
            if(!this.user_name){
                this.$message.error('请输入姓名')
            }else if(!this.imageUrl){
                this.$message.error('请上传头像')
            }else if(!this.Personalprofile){
                this.$message.error('填写个人简介')
            }else if(!this.agree){
                this.$message.error('勾选同意协议')
            }else{
                let data = {
                    username:this.user_name,
                    avatar:this.image_id,
                    brief:this.Personalprofile,
                    explain:this.Personaldescription,
                    type:this.type
                }
                this.$api.userpost('user.ExpertAdd',data,(res)=>{
                    if(res.data.status){
                        this.$message({
                            message:'提交成功，耐心等待审核结果',
                            type:'success'
                        })
                        this.$local.set('now_shenhe',true)
                        setTimeout(()=>{
                            this.$router.go(-1)
                        },2000)
                    }else{
                        this.$message.error(res.data.msg)
                    }
                })
            }

        },
        change(e){
            this.type = e 
        },
        // 上传图片的返回
         handleAvatarSuccess(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
            this.image_id  = res.data.image_id
        },
        beforeAvatarUpload(file) {
            // const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            // if (!isJPG) {
            // this.$message.error('上传头像图片只能是 JPG 格式!');
            // }
            if (!isLt2M) {
            this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            // return isJPG && isLt2M;
            return isLt2M;

      },
    //   加载审核结果
      load_result(){
                    this.$api.userpost('user.getExperDetail',{},(res)=>{
						if(res.data.status){
							console.log(res.data.data.status)
							switch (res.data.data.status) {
								case 1:
									this.result = 'now'
								    break;
								case 2:
									this.result = 'success'
									this.$local.del('now_shenhe')
								    break;
								case 3:
                                    this.result = 'fail'
                                    this.$local.del('now_shenhe')
								    break;
							}
						}else{
							this.$common.toast(res.data.msg)
						}
					})
      },
      get_resign(){
          this.show_result = false
          this.$api.userpost('user.getExperDetail',{},(res)=>{
              if(res.data.status){
                  console.log(res.data.data)
                  this.resign_obj = res.data.data
                  this.imageUrl = this.resign_obj.avatar
                  this.imageUrl = this.resign_obj.image_id
                  this.user_name = this.resign_obj.username
                  this.type = this.resign_obj.type
                  this.pression = this.resign_obj.type
                  this.Personalprofile = this.resign_obj.brief
                  this.Personaldescription = this.resign_obj.explain
              }else{
                  this.$message.error(res.data.msg)
              }
          })
      }
    }    
}
</script>
<style lang="scss" scoped>
 .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
.service_title{
		font-size: 23px;
        margin-left: 38px;
        margin-top: 24px;
	}
 .box{
        margin-top: 15px;
        width: 1140px;
        // height: 467px;
        background: #FFFFFF;
        opacity: 1;
        border-radius: 10px;    
        padding-bottom: 20px;
    }
    .avatar{
        margin: 0 auto;
        height: 145px;
        width: 1036px;
        border-bottom: 1px solid #E9E9E9;
        display: flex;
        align-items: center;
    }
    .avatar_image{
        height: 100px;
        width: 100px;
        border-radius: 50%;
    }
     .name{
        margin: 0 auto;
        height: 84px;
        width: 1036px;
        border-bottom: 1px solid #E9E9E9;
        display: flex;
        align-items: center;
        input{
            outline-style: none;
            border: none;
            font-size: 18px;
        }
    }
    .desc{
        margin: 0 auto;
        width: 1036px;
        display: flex;
    }
    .smallTitle{
        width: 120px;
        text-align: left;
        font-size:18px ;
    }
    .baocun{
        width: 210px;
        height: 49px;
        background: linear-gradient(162deg, #3C7DE2 0%, #2163C7 100%);
        opacity: 1;
        border-radius: 25px;
        line-height: 49px;
        text-align: center;
        font-size:23px;
        color: #FFF;
        margin: 51px 0 0 33px;
    }
    .text_class{
        padding: 20px;
        width: 683px;
        height: 136px;
        background: #FFFFFF;
        border: 1px solid #EFEFEF;
        opacity: 1;
        border-radius: 10px;
        margin-top: 30px;
        outline-style: none;
        font-size: 18px;
    }
    .mask{
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999;
      height: 100%;
      width: 100%;
      background: rgba($color: #000000, $alpha: .3);
      display: flex;
      align-items: center;
      justify-content: center;
     }
  .result_box{
      position: relative;
        width: 547px;
        height: 526px;
        background: #FFFFFF;
        opacity: 1;
        border-radius: 30px;
        .close_icon{
            height: 36px;
            width: 36px;
            position: absolute;
            top: 32px;
            right: 25px;
        }
        .result_text{
            font-size: 28px;
            font-weight: bold;
            color: #666;
        }
  }
  .resign_button{
      width: 230px;
        height: 53px;
        background: linear-gradient(162deg, #3C7DE2 0%, #2163C7 100%);
        opacity: 1;
        border-radius: 27px;
        font-size: 26px;
        color: #FFF;
        line-height: 53px;
        text-align: center;
  }
</style>